import API from "../lib/TimeEditAPI";
import Log from "../lib/Log";
import Language from "../lib/Language";
import APIError from "../lib/APIError";
import ReservationConstants from "../lib/ReservationConstants";
import _ from "underscore";
import { TimeConstants } from "../lib/TimeConstants";

const cancel = function (id, callback, useNewReservationGroups = false) {
    API.cancelReservation(
        id,
        { addGroupReservations: !useNewReservationGroups, longOperation: true },
        (inResults) => {
            const results = inResults.parameters;
            let foundError = false;

            for (let i = 0; i < results.length; i++) {
                if (results[i].result && results[i].result < 0) {
                    Log.warning(results[i].details);
                    foundError = true;
                    break;
                }
            }

            if (callback) {
                callback(!foundError);
            }
        }
    );
};

const get = function (ids, callback) {
    API.getReservations(ids, callback);
};

const getCancelled = function (ids, callback) {
    API.getReservationsCancelled(ids, callback);
};

const getHistory = function (ids, callback) {
    API.getReservationsHistory(ids, callback);
};

const getValidStatus = function (statusList, callback) {
    API.getValidReservationStatus(statusList, callback);
};

const doExport = function (ids, includeExtraInfo, callback) {
    API.exportReservations(ids, includeExtraInfo, callback);
};

const save = function (
    entry,
    inFluffy,
    callback,
    allowIncomplete = false,
    allowAvailabilityOverlap = false,
    isWaitingListReservation = false,
    useAsyncSave = true
) {
    // We at least require an entry here.
    if (!entry) return;

    const fluffy = inFluffy.toJson();
    fluffy.incomplete = allowIncomplete;
    fluffy.reservation = entry.reservationids;
    fluffy.availability_overlap = allowAvailabilityOverlap;

    // If modifying an existing reservation, set template group to "All"
    if (entry.reservationids && entry.reservationids.length > 0) {
        fluffy.template_group = 0;
    }

    if (entry.capacityReservationId) {
        fluffy.capacityReservationId = entry.capacityReservationId;
    } else if (entry.capacityReservationIds && entry.capacityReservationIds.length > 0) {
        fluffy.capacityReservationId = entry.capacityReservationIds[0];
    }
    fluffy.begin_time = entry.startTimes.map((time) => time.getMts());
    fluffy.end_time = entry.endTimes.map((time) => time.getMts());

    const objects: any[] = [];
    if (entry.objects && entry.types) {
        for (let i = 0; i < entry.types.length; i++) {
            objects.push({ id: entry.objects[i], typeid: entry.types[i] });
        }
    }

    const performReserve = () => {
        API.reserveMcFluffy(fluffy, objects, useAsyncSave, (result) => {
            const res = result.parameters[0];
            // eslint-disable-next-line no-alert
            if (
                res.result === ReservationConstants.ERROR_AVAILABILITY_OVERLAP_POSSIBLE &&
                // eslint-disable-next-line no-alert
                window.confirm(Language.get("nc_no_available_time_ignore_availability"))
            ) {
                save(
                    entry,
                    inFluffy,
                    callback,
                    allowIncomplete,
                    true,
                    isWaitingListReservation,
                    useAsyncSave
                );
                return null;
            }
            if (res.details) {
                return callback(new APIError(res.details, res.result));
            }
            const ids = result.parameters[1]
                ? _.pluck(result.parameters[1], "id")
                : [res.reference];
            return callback(res, ids);
        });
    };

    if (isWaitingListReservation) {
        const timeSlots = _.flatten(
            entry.startTimes.map((start, index) => ({
                class: "timeslot",
                begin: start.getMts(),
                end: entry.endTimes[index].getMts(),
            }))
        );
        API.moveReservationsFromWaitingList(
            entry.reservationids[0],
            timeSlots,
            inFluffy.objectItems.filter((item) => item.double).map((item) => item.object),
            allowAvailabilityOverlap,
            fluffy.capacityReservationId ? [fluffy.capacityReservationId] : undefined,
            useAsyncSave,
            (result) => {
                const processedResult = _handleChangeResult(result, false, entry.reservationids[0]);
                console.log(processedResult);
                if (processedResult instanceof APIError) {
                    return callback(processedResult, [], []);
                }
                performReserve();
            }
        );
    } else {
        performReserve();
    }
};

const modifyCluster = function (
    entries,
    isCopy,
    allowDoubleObjects,
    allowAvailabilityOverlap,
    originalHeaderObjects,
    callback,
    useNewReservationGroups = false,
    useAsyncSave = true
) {
    if (!Array.isArray(entries)) {
        // eslint-disable-next-line no-param-reassign
        entries = [entries];
    }
    let timeSlots = entries.map((entry) =>
        entry.startTimes.map((start, index) => ({
            class: "timeslot",
            begin: start.getMts(),
            end: entry.endTimes[index].getMts(),
        }))
    );

    if (!useNewReservationGroups) {
        timeSlots = _.flatten(timeSlots);
    }

    const reservationIds = useNewReservationGroups
        ? entries.map((entry) => entry.reservationids)
        : _.flatten(entries.map((entry) => entry.reservationids));
    const newHeaderObjects = entries[0].objects || [];
    const isEqualHeaderObjects = _.isEqual(newHeaderObjects, originalHeaderObjects);
    let capacityReservationIds = entries.map((entry) => entry.capacityReservationId);
    if (_.every(capacityReservationIds, (id) => !id)) {
        capacityReservationIds = undefined;
    }
    API.reservationClusterChangeTime(
        reservationIds,
        _.flatten(entries.map((entry) => entry.groups)),
        timeSlots,
        true,
        isCopy,
        allowDoubleObjects,
        allowAvailabilityOverlap,
        isEqualHeaderObjects ? [] : newHeaderObjects,
        isEqualHeaderObjects ? [] : originalHeaderObjects,
        capacityReservationIds,
        useAsyncSave,
        (result) => {
            const processedResult = _handleChangeResult(result, isCopy, reservationIds);
            if (processedResult instanceof APIError) {
                return callback(processedResult, [], []);
            }
            return callback(
                processedResult.status,
                processedResult.texts,
                processedResult.resultingIds
            );
        }
    );
};

const move = function (
    entries,
    allowDoubleObjects,
    allowAvailabilityOverlap,
    originalHeaderObjects,
    callback,
    useNewReservationGroups = false,
    useAsyncSave = true
) {
    return modifyCluster(
        entries,
        false,
        allowDoubleObjects,
        allowAvailabilityOverlap,
        originalHeaderObjects,
        callback,
        useNewReservationGroups,
        useAsyncSave
    );
};

const copy = function (
    entry,
    allowDoubleObjects,
    allowAvailabilityOverlap,
    originalHeaderObjects,
    callback,
    useNewReservationGroups = false,
    useAsyncSave = true
) {
    return modifyCluster(
        entry,
        true,
        allowDoubleObjects,
        allowAvailabilityOverlap,
        originalHeaderObjects,
        callback,
        useNewReservationGroups,
        useAsyncSave
    );
};

const moveToWaitingList = function (
    reservationIds: number[],
    createSingleReservation: boolean,
    useAsyncSave: boolean,
    callback
) {
    API.moveReservationsToWaitingList(
        reservationIds,
        createSingleReservation,
        useAsyncSave,
        (result) => {
            callback(result);
        }
    );
};

const _handleChangeResult = function (result, isCopy, reservationIds) {
    const status = result[0];

    if (status.details) {
        return new APIError(status.details, status.result);
    }

    const resultingIds = result[1].map((reservation) => reservation.id);
    const standardDelta = Math.abs(result[2]);
    const asymmetricIds = result[3].map((reservation) => reservation.id);
    const originalIds = result[4] ? result[4].map((reservation) => reservation.id) : reservationIds;

    const texts: any[] = [];

    // Inform user if cluster operation was asymmetric
    if (!isCopy && asymmetricIds && asymmetricIds.length > 0) {
        const addedIds = _.difference(asymmetricIds, originalIds);
        if (addedIds.length > 0) {
            if (addedIds.length === 1) {
                texts.push(Language.get("nc_asymmetric_one_reservation_added."));
            } else {
                texts.push(Language.get("nc_asymmetric_reservations_added", addedIds.length));
            }
        }
        const removedIds = _.difference(originalIds, resultingIds);
        if (removedIds.length > 0) {
            if (removedIds.length === 1) {
                texts.push(Language.get("nc_asymmetric_one_reservation_removed."));
            } else {
                texts.push(Language.get("nc_asymmetric_reservations_removed", removedIds.length));
            }
        }
        const movedIds = _.difference(asymmetricIds, addedIds, removedIds);
        if (movedIds.length > 0) {
            if (movedIds.length === 1) {
                texts.push(
                    Language.get(
                        "nc_asymmetric_reservation_moved_one_exception.",
                        Math.round(
                            standardDelta /
                                TimeConstants.SECONDS_PER_HOUR /
                                TimeConstants.HOURS_PER_DAY
                        ),
                        movedIds[0]
                    )
                );
            } else {
                texts.push(
                    Language.get(
                        "nc_asymmetric_reservation_moved",
                        Math.round(
                            standardDelta /
                                TimeConstants.SECONDS_PER_HOUR /
                                TimeConstants.HOURS_PER_DAY
                        ),
                        movedIds.length,
                        movedIds.join(", ")
                    )
                );
            }
        }
    }

    return { status, texts, resultingIds };
};

export const Reservation = {
    cancel,
    get,
    getCancelled,
    getHistory,
    getValidStatus,
    export: doExport,
    save,
    move,
    copy,
    moveToWaitingList,
};
